<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="moduleId"
      >
        <a-input
          v-decorator="['moduleId',{ initialValue:'0' }]"
          name="moduleId"></a-input>
      </a-form-item>

      <a-form-item
        label="模块标题"
      >
        <a-input
          v-decorator="[
            'title',
            {rules: [{ required: true, message: '请输入模块标题' }]}
          ]"
          name="title"
          placeholder="请输入模块标题"/>
      </a-form-item>
      <a-form-item
        label="模块名称"
      >
        <a-input
          v-decorator="[
            'name',
            {rules: [{ required: true, message: '请输入模块名称' }]}
          ]"
          name="title"
          placeholder="请输入模块名称"/>
      </a-form-item>
      <a-form-item
        label="备注"
      >
        <a-input
          v-decorator="[
            'remarks',
            {rules: [{ required: true, message: '请输入备注' }]}
          ]"
          name="remarks"
          placeholder="请输入备注"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        form: this.$form.createForm(this)
      }
    }
  }
</script>
