var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-form-item',{attrs:{"hidden":"","label":"moduleId"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['moduleId',{ initialValue:'0' }]),expression:"['moduleId',{ initialValue:'0' }]"}],attrs:{"name":"moduleId"}})],1),_c('a-form-item',{attrs:{"label":"模块标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {rules: [{ required: true, message: '请输入模块标题' }]}
        ]),expression:"[\n          'title',\n          {rules: [{ required: true, message: '请输入模块标题' }]}\n        ]"}],attrs:{"name":"title","placeholder":"请输入模块标题"}})],1),_c('a-form-item',{attrs:{"label":"模块名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {rules: [{ required: true, message: '请输入模块名称' }]}
        ]),expression:"[\n          'name',\n          {rules: [{ required: true, message: '请输入模块名称' }]}\n        ]"}],attrs:{"name":"title","placeholder":"请输入模块名称"}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remarks',
          {rules: [{ required: true, message: '请输入备注' }]}
        ]),expression:"[\n          'remarks',\n          {rules: [{ required: true, message: '请输入备注' }]}\n        ]"}],attrs:{"name":"remarks","placeholder":"请输入备注"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', { initialValue: 1 }]),expression:"['status', { initialValue: 1 }]"}],attrs:{"name":"status"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("启用")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("禁用")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }